import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { graphql } from 'gatsby'
import useDateFns from '@hooks/useDateFns'
import styled from 'styled-components'
import { get } from 'lodash'
import useStaticModule from '@hooks/useStaticModule'

import TracklineBackground from '@objects/tracklineBackground'
import Container from '@objects/container'
import { IntroCopy } from '@objects/copy'
import Stage from '@components/stage'
import TeaserCardList from '@components/teaserCardList'
import DoubleBlogCard from '@components/doubleBlogCard'
import PageDownload from '@components/pageDownload'

const StyledIntroCopy = styled(IntroCopy)`
  h2 {
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: ${({ theme }) => theme.lineHeight['9']};
    margin-top: ${({ theme }) => theme.spacing['9']};
  }
`

function Umsetzung({ data, pageContext }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()
  const { getStaticFieldMedia, getStaticFieldValue } = useStaticModule(
    pageContext.modules
  )

  const stageProps = pageContext.modules
    ? {
        image: getStaticFieldMedia(
          'implementation.stage',
          'implementation.stage.image'
        ),
        article: {
          headline: getStaticFieldValue(
            'implementation.stage',
            'implementation.stage.headline'
          ),
          tag: {
            name: getStaticFieldValue(
              'implementation.stage',
              'implementation.stage.tag'
            ),
            to: null,
          },
        },
      }
    : null

  const introCopy = get(data, 'contentfulTopicList.introCopy')
    .childMarkdownRemark?.html

  const teaserList = get(data, 'contentfulTopicList.articles').map(
    (article) => ({
      type: 'topic',
      image: article.image,
      headline: article.title,
      copy: article.teaserCopy.childMarkdownRemark?.html,
      link: `/${
        article.article___list?.type === 'News' ? 'newsroom' : 'blog'
      }/${article.slug}/`,
    })
  )

  const blogCards = {
    headline: intl.formatMessage({ id: 'section.blogdouble.headline' }),
    button: {
      label: intl.formatMessage({ id: 'button.blog' }),
      to: '/blog/',
    },
    cards: (() => {
      // get first 2 articles in DESC order
      return get(data, 'blogArticles')
        ?.articles.sort((a, b) => differenceInDays(b.createdAt, a.createdAt))
        .slice(0, 2)
        .map((card) => ({
          image: card.image,
          headline: card.title,
          copy: card.teaserCopy?.childMarkdownRemark?.html,
          tag: card.category?.title,
          date: card.createdAt,
          link: card.slug,
        }))
    })(),
  }

  const downloads = []
  const pdf = get(data, 'contentfulTopicList.pdf')
  pdf && downloads.push(pdf)

  return (
    <>
      {stageProps && <Stage type="headlineimage" {...stageProps} />}

      <Container role="region" aria-label="Umsetzung">
        <TracklineBackground track={5} mirror top={'10%'} />
        <TracklineBackground track={3} bottom={'30%'} />
        <TracklineBackground track={1} right top={'15%'} />
        <TracklineBackground track={2} right bottom={'10%'} />

        <div className="lg:w-8/12 lg:mx-auto">
          <StyledIntroCopy
            div
            className="mb-10"
            html={introCopy}
            parseGlossaryCopy
          />

          <TeaserCardList
            className={'space-y-9 md:space-y-10 mb-10 md:mb-12'}
            teaserList={teaserList}
          />
          {!!downloads.length ? <PageDownload files={downloads} /> : null}
        </div>
      </Container>

      <DoubleBlogCard
        className="mb-11"
        headline={blogCards.headline}
        button={blogCards.button}
        cards={blogCards.cards}
      />
    </>
  )
}

Umsetzung.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Umsetzung

export const pageQuery = graphql`
  query UmsetzungPageQuery($locale: String!) {
    contentfulTopicList(
      identifier: { eq: "umsetzung" }
      node_locale: { eq: $locale }
    ) {
      id
      title
      introCopy {
        childMarkdownRemark {
          html
        }
      }
      articles {
        title
        slug
        image {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        category {
          title
        }
        createdAt
        article___list {
          type
        }
      }
      pdf {
        file {
          contentType
          url
          details {
            size
          }
        }
        title
      }
    }

    blogArticles: contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "blog" }
    ) {
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        category {
          title
        }
      }
    }
  }
`
